import React, { useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Rating,
  useTheme,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import UserAvatar from '../../../components/common/UserAvatar';
import { getImage } from '../../../utils/commonFunctions';

export const DiscountBanner = (props) => {
  const { containerStyle } = props;
  return (
    <Alert
      severity="error"
      sx={[
        {
          backgroundColor: (theme) => theme.palette.error.main,
          mt: 1,
          py: 0.2,
        },
        containerStyle,
      ]}
      icon={
        <Icon
          style={{ color: 'white', marginTop: 2 }}
          height={18}
          width={18}
          icon={'mdi:discount'}
        />
      }
    >
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant="subtitle2"
          sx={{
            display: 'block',
            color: 'white',
            fontWeight: 800,
          }}
        >
          Book Quickly: Secure your spot with this therapist!
        </Typography>
      </Box>
    </Alert>
  );
};

DiscountBanner.propTypes = {
  containerStyle: PropTypes.any,
};

const TherapistCard = ({ data }) => {
  const theme = useTheme();
  // Define your button component with styled
  const MyButton = styled(Button)({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.primary.main}`,
    transition: 'background-color 200ms linear',
  });

  const [showMore, setShowMore] = useState(false);

  const qualification = useMemo(() => {
    const str = data?.info?.qualifications?.map((des) => des.name).join(', ');
    return showMore ? str : str.slice(0, 80);
  }, [data?.info?.qualifications, showMore]);

  const ageGrp = data?.ageGroup?.reduce(
    (acc, obj, index) =>
      (acc += `${obj.lowest}-${obj.highest}${
        data?.ageGroup?.length - 1 !== index ? ', ' : ''
      }`),
    ''
  );
  const navigate = useNavigate();
  return (
    <Paper
      sx={{
        'height': '100%',
        'p': 3,
        'borderRadius': '32px',
        'backgroundColor': (theme) => theme.palette.background.lightBlue,
        '&:hover': {
          backgroundColor: (theme) => theme.palette.common.white,
        },
        'transition': 'background-color 400ms linear',
      }}
    >
      <Stack height={'100%'}>
        <Stack
          gap={2}
          flexDirection={'row'}
          alignItems={'center'}
          // justifyContent={'flex-start'}
        >
          <UserAvatar
            src={getImage({ id: data?._id, role: 'therapists' })}
            size={90}
          />
          {/* <img
          width={80}
          height={80}
          style={{ objectFit: 'cover' }}
          src={img || defaultImage}
          alt="therapist"
        /> */}
          <Stack gap={1}>
            <Stack>
              <Typography sx={{ wordBreak: 'break-word' }} variant="h5">
                {data.name}
              </Typography>
            </Stack>
            <Typography color={'text.secondary'} variant="body2">
              {data?.info?.designation?.map((des) => des.name).join(', ')}
            </Typography>
            <Stack
              gap={1}
              // height={24}
              flexDirection={'row'}
              alignItems={'center'}
              flexWrap={'wrap'}
            >
              {data?.session?.rating && (
                <>
                  <Rating
                    name="read-only"
                    value={data?.session?.rating}
                    readOnly
                  />
                  <Typography variant="subtitle2" lineHeight={0}>
                    {data?.session?.rating
                      ? Number(data?.session?.rating).toFixed(1)
                      : 0}
                  </Typography>
                </>
              )}
              {data?.ranking?.isPaidMember && (
                <>
                  <Chip
                    label={'Sponsored'}
                    size="small"
                    variant="filled"
                    color={'default'}
                  />
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
        {/* <Divider sx={{ my: 2 }} /> */}
        <Stack flex={1} mt={2}>
          <Stack mt={1} gap={1} flexDirection={'row'} alignItems={'center'}>
            <Icon fontSize={24} icon={'cil:education'} />
            <Typography color={'text.primary'} variant="buttonText">
              {'Qualifications'}
            </Typography>
          </Stack>
          <Stack flex={1} mt={1} gap={1}>
            <Typography
              sx={{ display: 'inline' }}
              color={'text.secondary'}
              variant="body1"
            >
              {qualification}{' '}
              {qualification.length > 79 && (
                <span
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => setShowMore((prev) => !prev)}
                >
                  {showMore ? 'show less' : 'show more'}{' '}
                </span>
              )}
            </Typography>
          </Stack>
          <Stack mt={1} gap={1}>
            <Stack gap={1} flexDirection={'row'} alignItems={'center'}>
              <Icon
                fontSize={24}
                icon={'fluent-emoji-high-contrast:children-crossing'}
              />
              <Typography color={'text.primary'} variant="buttonText">
                {'Age Group'}
              </Typography>
            </Stack>
            <Typography
              sx={{ display: 'inline' }}
              color={'text.secondary'}
              variant="body1"
            >
              {ageGrp}
            </Typography>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Stack
            justifyContent={'space-between'}
            gap={1}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <Typography color={'text.secondary'} variant="body1">
              {'Sessions Taken'}
            </Typography>
            <Typography variant="h5">{data.session?.taken || 0}</Typography>
          </Stack>{' '}
          <Stack
            mt={1}
            justifyContent={'space-between'}
            gap={1}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <Typography color={'text.secondary'} variant="body1">
              {'Price per session'}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <Typography
                variant="h5"
                sx={{
                  mr: !data.discountPrice ? 0 : 1,
                  color: (theme) => theme.palette.primary,
                }}
              >
                {!data?.discountPrice
                  ? `₹ ${data.session?.charge}`
                  : `₹ ${data?.discountPrice}`}
              </Typography>
              {data?.discountPrice ? (
                <Typography
                  variant="body"
                  sx={{
                    fontWeight: 800,
                    textDecorationLine: 'line-through',
                    color: (theme) => theme.palette.error.main,
                    mb: 0.1,
                  }}
                >
                  ₹ {data.session?.charge}
                </Typography>
              ) : null}
            </Box>
          </Stack>
          {!data?.discountPrice ? null : <DiscountBanner />}
          <Divider sx={{ my: 2, mt: !data?.discountPrice ? 2 : 1 }} />
          <MyButton
            onClick={() => {
              navigate(`/therapist/${data._id}`);
            }}
          >
            {' '}
            <Typography variant="buttonText">View Details</Typography>
          </MyButton>
        </Stack>
      </Stack>
    </Paper>
  );
};

TherapistCard.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.any,
    discountPrice: PropTypes.number,
    ageGroup: PropTypes.any,
    info: PropTypes.shape({
      designation: PropTypes.any,
      languages: PropTypes.any,
      qualifications: PropTypes.array,
    }),
    languages: PropTypes.any,
    name: PropTypes.any,
    profileImage: PropTypes.any,
    ranking: PropTypes.shape({
      isPaidMember: PropTypes.any,
    }),
    rating: PropTypes.any,
    session: PropTypes.shape({
      charge: PropTypes.any,
      rating: PropTypes.any,
      taken: PropTypes.number,
    }),
  }),
};

export default TherapistCard;
