import { Grid, Typography } from '@mui/material';

import saleHanging from '../../../../assets/images/sale-hanging.png';

const OfferBanner = () => {
  return (
    <Grid
      container
      sx={{
        borderRadius: 4,
        overflow: 'hidden',
        height: { xs: 'auto', md: 180 },
        width: '100%',
        background:
          'linear-gradient(to right, #F5FAE5, #d6e69c, #CFE67B, #BCDC49, #B0C75C, #90AE21)',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
        alignItems: 'center',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: { xs: 100, md: '100%' },
          order: { xs: 1, md: 0 },
          padding: { xs: 1, md: 2 },
        }}
      >
        <img
          src={saleHanging}
          alt="sale-hanging"
          style={{
            objectFit: 'contain',
            width: '80%',
            maxWidth: '250px',
            height: 'auto',
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: { xs: 'center', md: 'left' },
          textAlign: 'center',
          padding: { xs: 2, md: 3 },
          order: { xs: 2, md: 1 },
          height: { xs: 'auto', md: '100%' },
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: { xs: 18, sm: 22, md: 28 },
            fontWeight: 900,
            marginRight: 1,
          }}
        >
          The Blessed Ones therapists first session{' '}
          <Typography
            component="span"
            sx={{
              fontSize: { xs: 18, sm: 22, md: 28 },
              color: (theme) => theme.palette.error.dark,
              fontWeight: 900,
              textWrap: 'nowrap',
            }}
          >
            @ INR 21
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OfferBanner;
