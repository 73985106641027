/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { Icon } from '@iconify/react';
import {
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  Divider,
  CircularProgress,
} from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Carousel from 'react-material-ui-carousel';
import { useNavigate } from 'react-router-dom';

import OfferBanner from './components/OfferBanner';

import comprehensiveSupport from '../../../assets/images/comprehensive-support-Img.png';
import convenience from '../../../assets/images/convenience-Img.png';
import expertTherapists from '../../../assets/images/expert-therapists-Img.png';
import mainImage from '../../../assets/images/main-Img.png';
import trustedPlatform from '../../../assets/images/trusted-platform-Img.png';
import ButtonWithLoading from '../../../components/common/ButtonWithLoading';
import UserAvatar from '../../../components/common/UserAvatar';
import config from '../../../config';
import { defaultSpacing } from '../../../defaults';
import useGetUserData from '../../../hooks/useGetUserData';
import { useGetUpcomingSessionQuery } from '../../../store/apis/session';
import { getImage } from '../../../utils/commonFunctions';

var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
const SideImagComp = ({ sections }) => {
  return (
    <Stack
      gap={3}
      alignItems={'center'}
      justifyContent={'center'}
      sx={{ flexDirection: { xs: 'column', sm: 'row', md: 'column' } }}
    >
      {sections.map((section) => {
        return (
          <Box
            key={section.text}
            textAlign={'center'}
            position={'relative'}
            display={'flex'}
            justifyContent={'center'}
            height={'100%'}
            width={'100%'}
          >
            <img
              style={{ borderRadius: '40px' }}
              height={'100%'}
              width={'100%'}
              src={section.img}
              alt="playBased"
            />

            <Box
              sx={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: '40px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                padding: 2,
                flexDirection: 'column',
              }}
              position={'absolute'}
            >
              <Typography
                // sx={{ transform: 'translateY(-50%)' }}
                color={'white'}
                textAlign={'left'}
                variant="subtitle2"
                fontWeight={'fontWeightBold'}
              >
                {section.text}
              </Typography>
              {section.subtitle && (
                <Typography
                  textAlign={'left'}
                  // sx={{ transform: 'translateY(-50%)' }}
                  color={'white'}
                  variant="subtitle2"
                  fontWeight={'fontWeightBold'}
                >
                  {section.subtitle}
                </Typography>
              )}
            </Box>
          </Box>
        );
      })}
    </Stack>
  );
};

SideImagComp.propTypes = {
  sections: PropTypes.any,
};

const leftSections = [
  {
    text: 'Expert Therapists:',
    subtitle: 'Select from a range of top-rated professionals.',
    img: expertTherapists,
  },
  {
    text: 'Comprehensive Support:',
    subtitle:
      'Receive session notes, progress updates, and continuous support.',
    img: comprehensiveSupport,
  },
];
const rightSections = [
  {
    text: 'Convenience:',
    subtitle: 'Book and attend sessions online from anywhere.',
    img: convenience,
  },
  {
    text: 'Trusted Platform:',
    subtitle: 'Secure, integrated video conferencing and payment system.',
    img: trustedPlatform,
  },
];
const centerSection = [
  {
    text: 'Why Choose Coables?',
    subtitle:
      'At Coables, we believe every child deserves access to the best therapy, regardless of their location. Our platform connects families with qualified, experienced therapists who provide personalized online therapy sessions tailored to the unique needs of each child. From the comfort of your home, watch your child thrive and grow with our engaging, supportive, and professional services.',
    img: mainImage,
  },
];

const TopSection = ({ children }) => {
  const { data: userData, role } = useGetUserData();
  const { data: sessionData, isLoading } = useGetUpcomingSessionQuery(
    {},
    {
      skip: !userData?._id,
      refetchOnMountOrArgChange: true,
    }
  );
  const navigate = useNavigate();
  const session = sessionData?.[0];
  const therapistImg = getImage({
    id: session?.therapist?._id,
    role: 'therapists',
  });
  const parentImg = getImage({
    id: session?.parent?._id,
    role: 'parents',
  });
  const start12Hour = dayjs(session?.startTime).format('h:mm A');
  const end12Hour = dayjs(session?.endTime).format('h:mm A');

  const upcomingSession = ({ img, name }) => {
    return (
      <Paper
        sx={{
          p: 3,
          boxShadow: '0px 3px 10px 0px #07404B26',
          borderRadius: '15px',
        }}
      >
        <Typography variant="h6">Upcoming Session</Typography>
        <Divider sx={{ py: 1 }} />
        <Stack
          mt={2}
          gap={1}
          alignItems={{ xs: 'flex-start', md: 'center' }}
          direction={{ xs: 'column', md: 'row' }}
        >
          <Stack
            width={{ xs: '100%', md: '50%' }}
            alignItems={'center'}
            gap={1}
            direction={'row'}
          >
            <UserAvatar alt={session?.therapist?.name} src={img} size={40} />
            <Typography color={'text.primary'} variant="buttonText">
              Session with {name}
            </Typography>
          </Stack>
          <Stack
            width={{ xs: '100%', md: '50%' }}
            justifyContent={'space-between'}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            direction={{ xs: 'column', md: 'row' }}
          >
            <Stack
              gap={2}
              direction={'row'}
              mt={{ xs: 2, md: 0 }}
              alignItems={{ xs: 'flex-start', md: 'center' }}
            >
              <Stack gap={1} direction={'row'} alignItems={'center'}>
                <Icon icon="oui:calendar" color={'#53C5F3'} size={18} />
                <Typography variant="subtitle2" color={config?.textColor}>
                  {dayjs(session?.startTime).format('DD MMM YYYY')}
                </Typography>
              </Stack>
              <Stack gap={1} direction={'row'} alignItems={'center'}>
                <Icon icon="ic:baseline-alarm" color={'#FF9E4C'} size={18} />
                <Typography variant="subtitle2" color={config?.textColor}>
                  {start12Hour} - {end12Hour}{' '}
                </Typography>
              </Stack>
            </Stack>
            {console.log('session', session)}
            <ButtonWithLoading
              onClick={() => {
                navigate(
                  `/${role}/join-meeting?meetingId=${session.videoCallId}&name=${userData.name}&sessionId=${session._id}`
                );
              }}
              disabled={
                !dayjs().isBetween(
                  dayjs(session.startTime).subtract(5, 'minute'),
                  dayjs(session.endTime).add(session?.bufferTime, 'minute')
                )
              }
              customStyle={{
                px: 4,
                display: { xs: 'block', md: 'block' },
                mt: { xs: 2, md: 0 },
              }}
              fullWidth={false}
              size="small"
              title="Join"
            />
          </Stack>
        </Stack>
      </Paper>
    );
  };
  return (
    <Stack px={defaultSpacing} width={'100%'} alignItems={'center'}>
      <Stack width={'100%'} gap={4}>
        <OfferBanner />
        <Stack width={'100%'} gap={1}>
          <Typography variant="h4">
            Available Therapies & Counselling
          </Typography>
          <Typography variant="body1  ">
            If kids can't reach out to us, we will reach out to them.
          </Typography>
        </Stack>
        <Stack display={{ xs: 'block', md: 'none' }}>
          <Carousel>
            {leftSections
              .concat(rightSections)
              .concat(centerSection)
              .map((section) => {
                return (
                  <Box
                    key={section.text}
                    textAlign={'center'}
                    position={'relative'}
                    display={'flex'}
                    justifyContent={'center'}
                    height={'100%'}
                    width={'100%'}
                  >
                    <img
                      style={{ borderRadius: '40px' }}
                      height={'100%'}
                      width={'100%'}
                      src={section.img}
                      alt="playBased"
                    />
                    <Box
                      sx={{
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        borderRadius: '40px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-end',
                        padding: 2,
                      }}
                      position={'absolute'}
                      bottom={20}
                    >
                      <Typography
                        textAlign={'left'}
                        color={'white'}
                        variant="buttonText"
                        sx={{
                          fontSize: { xs: 12, sm: 16 },
                        }}
                      >
                        {section.text}
                      </Typography>
                      {section.subtitle && (
                        <Typography
                          textAlign={'left'}
                          color={'white'}
                          variant="caption"
                          fontWeight={'fontWeightBold'}
                          sx={{
                            fontSize: { xs: 10, sm: 12 },
                          }}
                        >
                          {section.subtitle}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  // <img
                  //   height={'100%'}
                  //   width={'100%'}
                  //   alt={img.text}
                  //   key={img.text}
                  //   src={img.img}
                  // />
                );
              })}
          </Carousel>
        </Stack>

        <Grid display={{ xs: 'none', md: 'inherit' }} spacing={3} container>
          <Grid item xs={12} md={3} lg={3}>
            <SideImagComp sections={leftSections} />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Box
              height={'100%'}
              width={'100%'}
              display={'flex'}
              justifyContent={'center'}
              position={'relative'}
            >
              <img
                style={{ borderRadius: '40px' }}
                height={'100%'}
                width={'100%'}
                src={mainImage}
                alt="mainImage"
              />
              <Box
                sx={{
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  borderRadius: '40px',
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
                position={'absolute'}
                bottom={0}
                top={0}
              >
                <Stack m={3}>
                  <Typography
                    color={'white'}
                    sx={{ fontSize: { sm: '24px', lg: '34px' } }}
                    variant="h4"
                  >
                    Why Choose Coables?
                  </Typography>{' '}
                  <Typography
                    sx={{ fontSize: { sm: '12px', lg: '16px' } }}
                    color={'white'}
                    variant="subtitle1"
                  >
                    At Coables, we believe every child deserves access to the
                    best therapy, regardless of their location. Our platform
                    connects families with qualified, experienced therapists who
                    provide personalized online therapy sessions tailored to the
                    unique needs of each child. From the comfort of your home,
                    watch your child thrive and grow with our engaging,
                    supportive, and professional services.
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <SideImagComp sections={rightSections} />
          </Grid>
        </Grid>
        <Stack>
          {isLoading && (
            <Box
              justifyContent={'center'}
              alignItems={'center'}
              display={'flex'}
              width={'100%'}
              py={3}
            >
              <CircularProgress />
            </Box>
          )}
          {session && !isLoading && (
            <Stack mt={2}>
              {upcomingSession({
                img: role === 'parents' ? therapistImg : parentImg,
                name:
                  role === 'parents'
                    ? session?.therapist?.name
                    : session?.parent?.name,
              })}
            </Stack>
          )}
        </Stack>
        {children}
      </Stack>
    </Stack>
  );
};

TopSection.propTypes = {
  children: PropTypes.any,
};

export default TopSection;
